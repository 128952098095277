<template>
  <button :disabled="disabled" :class="[type, (direction==''?'direction-'+signal.direction:'direction-'+direction), (size==''?'size-'+signal.size:'size-'+size), (sub?'withsub':''), (fancynav.length>0?'fancynav':'')]" ref="button">
    <template v-if="icon.length&&(typeof icon[2]=='undefined'||icon[2]!='after')">
      <!-- <Icon class="icon" :type="icon[0]" :icon="icon[1]"/> -->
      &nbsp;
    </template>
    <template v-if="fancynav=='left'">
      <img :class="['icon', 'after-icon']" :style="'fill: black'" src="@/assets/files/arrow-left.svg" alt=""/>
    </template>
    <span>
      <slot></slot>
    </span>
    <span class="sub" v-if="sub">
      {{sub}}
    </span>
    <template v-if="fancynav=='right'">
      <img :class="['icon', 'before-icon']" :style="'fill: black'" src="@/assets/files/arrow-right.svg" alt=""/>
    </template>
    <template v-if="icon.length&&(typeof icon[2]!='undefined')&&icon[2]=='after'">
      &nbsp;
      <!-- <Icon class="icon after-icon" :type="icon[0]" :icon="icon[1]"/> -->
    </template>
  </button>
</template>

<script>
// import UIIcon from '@/components/UI/Icon';

export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: Array,
      default: () => {
        return []
      }
    },
    signal: {
      type: Object,
      default: () => {
        return {
          direction: 'horizontal',
          size: 'normal'
        }
      }
    },
    direction: {
      type: String,
      default: ''
    },
    fancynav: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    sub: {
      type: String,
      default: ''
    }
  },
  methods: {
    focus: function() {
      this.$refs.button.focus();
    }
  },
  components: {
    // Icon: UIIcon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colors.scss';

button, a {
  font-family: 'Asap', sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border: 0;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
  // padding-right: 8rem;
  text-align: left;
  display: inline-block;
  font-size: 1em;

  // &:before {
  //   position: absolute;
  //   content: "\203A";
  //   top: 50%;
  //   right: 2%;
  //   width: 20px;
  //   display: block;
  //   z-index: 1;
  //   font-size: 24px;
  //   margin-top: -14px;
  //   font-size: 1.4rem;
  //   margin-top: -.7rem;
  //   line-height: 1;
  // }
  .icon {
    margin-right: 5px;
    max-height: 24px;
    max-width: 20px;
  }

  .icon-secondary {
    fill: #FFFFFF;
    color:#FFFFFF;
  }
  .before-icon {
    margin-left: 15px;
  }
  .after-icon {
    margin-right: 15px;
  }

  &.withsub {
    .icon {
      margin-top: -6px;
    }
  }

  &.fancynav:enabled {
    background-color: transparent !important;
    border-bottom: 4px solid $dig-orange;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    transition: none;
    &:focus, &:hover, &.focus {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      color: $dig-text-dark !important;
      border: 4px solid $dig-orange;
    }
  }

  &.fancynav:disabled {
    background-color: transparent !important;
    border-bottom: 4px solid black;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-left: 4px;
    margin-top: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    transition: none;
    &:disabled {
      color: black !important;
      border-bottom: 4px solid black;
      opacity: 32%;
    }
  }

  .sub {
    display: inline-block;
    font-size: 9pt;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.primary {
    color: $dig-text-dark;
    background-color: $dig-orange;
    &:focus, &:hover, &.focus {
      color: #FFFFFF;
      background-color: $dig-dark;
    }
  }

  &.secondary {
    color: #FFFFFF;
    background-color: $dig-dark;
    &:focus, &:hover, &.focus {
      color: $dig-text-dark;
      background-color: $dig-orange;
    }
  }

  &.tertiary {
    color: #FFFFFF;
    background-color: $dig-purple;
    &:focus, &:hover, &.focus {
      color: $dig-dark;
      background-color: $dig-orange;
    }
  }

  &.disagree {
    color: $dig-text-dark;
    background-color: black;
    opacity: 32%;
    &:focus, &:hover, &.focus {
      opacity: 100%;
      color: $dig-dark;
      background-color: $dig-orange;
    }
  }

  &.agree {
    color: $dig-text-dark;
    background-color: $dig-new-success;
    &:focus, &:hover, &.focus {
      color: $dig-dark;
      background-color: $dig-orange;
    }
  }

  // &.serious {
  //   color: $hugr-button-serious-text;
  //   background-color: $hugr-button-serious-bg;
  //   &:focus, &:hover, &.focus {
  //     color: $hugr-button-serious-text-focus;
  //     background-color: $hugr-button-serious-bg-focus;
  //   }
  // }

  // &.transparent {
  //   color: $hugr-button-transparent-text;
  //   background-color: $hugr-button-transparent-bg;
  //   border: 1px solid $hugr-button-transparent-border;
  //   &:focus, &:hover, &.focus {
  //     border: 1px solid $hugr-button-transparent-border-focus;
  //   }
  // }

  // &.border {
  //   color: $hugr-button-border-text;
  //   background-color: $hugr-button-border-bg;
  //   border: 1px solid $hugr-button-border-border;
  //   &:focus, &:hover, &.focus {
  //     color: $hugr-button-border-text-focus;
  //     background-color: $hugr-button-border-bg-focus;
  //     border: 1px solid $hugr-button-border-border-focus;
  //   }
  // }

  // &.icon {
  //   background: transparent;
  //   width: 26px;
  //   height: 26px;
  //   display: inline-block;
  //   padding: 0;
  //   span {
  //     color: transparent;
  //   }
  // }

  // &.switch {
  //   background: transparent;
  //   // width: 20px;
  //   // height: 20px;
  //   display: inline-block;
  //   padding: 0;
  //   color: #262e37;
  //   background-color: #ffb23e;
  //   &[aria-checked="true"] {
  //     color: #ffb23e;
  //     background-color: #262e37;
  //   }
  // }

  // &.edit {
  //   padding: 5px 5px 5px 24px;
  //   background: transparent;
  //   color: #5e646a;
  //   border: 1px solid transparent;

  //   &:hover, &:focus {
  //     color: #262e37;
  //     border: 1px solid #5e646a;
  //   }

  //   &:before {
  //     content: "\270E";
  //     left: 0;
  //     top: 55%;
  //   }
  // }

  //size and direction
  &.direction-vertical {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  &.size-small {
    padding: 10px 10px 10px 10px;
    font-size: 10pt;
  }
  &.size-cookie {
    padding: 8px 24px;
    font-size: 18pt;
    line-height: 22px;
    font-weight: 700;
    width: 100%;
    height: 44px;
    text-align: center;
  }
  &.size-micro {
    padding: 5px;
    font-size: 10pt;
    &:before { //this might not be needed
      content: '';
    }
  }
}
</style>
