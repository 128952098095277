<template>
  <footer class="Footer">
    <Block v-if="footer" class="Footer" bg="new-dark-blue">
      <div class="Footer_Sections">
        <div class="Footer_Sections_Section">
          <h2>Get in touch</h2>
          <p>Do you have a project you need help with or a question you want to run past us?</p>
          <LinkButton class="Button" :short="true" :extraPad="true" type="primary" :to="'/contact/'">Contact us</LinkButton>
        </div>
        <div class="Footer_Sections_Section_Logos">
          <div class="Footer_Sections_Section_Logos_Socials">
            <div v-for="(linkedin, i) in footer.LinkedIn" :key="'linkedin-'+i" class="Footer_Sections_Section_Logos_Socials_Container">
              <a :href="linkedin.Link">
                <img src="@/assets/files/linkedin.svg" alt=""/>
                <span class="Footer_Sections_Section_Logos_Socials_Container_Text">{{linkedin.Text}}</span>
              </a>
            </div>
          </div>
          <div>
            <vue-markdown class="md" :source="footer.NileCompany" />
          </div>
          <div class="Footer_Sections_Section_Logos_Logos">
            <img class="logo" :src="urlBase+footer.CyberEssentialsLogo.url" alt="Cyber Essentials Plus Certified" />
            <img class="logo" :src="urlBase+footer.DisabilityConfidentLogo.url" alt="Disability Confident Employer" />
          </div>
        </div>
        <div class="Footer_Sections_Section">
          <div class="Footer_Nav" v-if="showLinks">
              <router-link class="Footer_Nav_Link" v-for="(link, i) in footer.Links" :key="i" :to="link.Link">{{link.Text}}</router-link>
          </div>
          <p class="Footer_Logos_Copy">
            <vue-markdown class="md" :source="footer.CopyrightRegisteredText" />
          </p>
        </div>
      </div>
    </Block>
  </footer>
</template>

<script>
import Block from "./UI/Block";
import LinkButton from "./UI/LinkButton";
import { useQuery } from "@vue/apollo-composable";
import { computed } from 'vue';
import gql from 'graphql-tag';

export default {
  name: "Footer",
  components: {
    Block,
    LinkButton,
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_STRAPIURL,
    };
  },
  setup() {
    const { result } = useQuery(gql`
      query {
        footerNew {
          CyberEssentialsLogo {
            url
          }
          DisabilityConfidentLogo {
            url
          }
          CopyrightRegisteredText,
          NileCompany,
          GetInTouch {
            Title,
            Body
          },
          LinkedIn {
            Text,
            Link
          }
          Links {
            Link
            Text
          }
        }
      }
    `);

    const footer = computed(() => result.value?.footerNew);

    return { footer };
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss">
  .md {
    p {
      display: inline;
    }
    a {
      color: #FFFFFF !important;
    }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables/_colors.scss";

.logo {
  max-height: 60px;
}

.Footer {
  &_Sections {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    &_Section {
      width: 33%;
      flex: 33%;
      h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        margin-top: 0px;
        font-style: italic;
      }
      &_Logos {
        width: 33%;
        flex: 33%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        &_Logos {
          display: flex;
          flex-direction: row;
          column-gap: 24px;
        }
        &_Socials {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &_Container {
            width: 45%;
            height: 20px;
            &_Text {
              height: 20px;
              margin-left: 8px;
            }
            a {
              display: flex;
              align-content: center;
              color: white;
            }
            a :hover{
              color: $dig-orange;
            }
          }
        }
      }
    }
  }
  &_Logos {
    width: 50%;
    img {
      height: 80px;
      margin-right: 16px;
    }
    &_Logo {
      height: 61px !important;
      vertical-align: top;
      margin-top: 8px;
      margin-right: 64px !important;
    }

    &_Copy {
      font-size: 0.8em;
      
    }
  }

  &_Nav {
    text-align: left;
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    a {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;

        text-decoration: none;

        &:hover,
        &:focus {
          color: $dig-orange;
        }
      }
  }
}

@media screen and (max-width: 1050px) {
  .Button {
    width: 100%;
  }
  .Footer {
    text-align: center;
  &_Sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 40px;
    justify-content: center;
    &_Section {
      flex: 100%;
      width: 100%;
      &_Logos {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        justify-content: center;
        margin-top: 16px;
        &_Logos {
          
          display: flex;
          flex-direction: row;
          column-gap: 24px;
          justify-content: center;
        }
        &_Socials {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 16px;
          &_Container {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 20px;
            &_Text {
              height: 20px;
              margin-left: 8px;
            }
            a {
              display: flex;
              align-content: center;
              color: white;
            }
            a :hover{
              color: $dig-orange;
            }
          }
        }
      }
    }
  }
  &_Logos {
    width: 50%;
    img {
      height: 80px;
      margin-right: 16px;
    }
    &_Logo {
      height: 61px !important;
      vertical-align: top;
      margin-top: 8px;
      margin-right: 64px !important;
    }

    &_Copy {
      font-size: 0.8em;
    }
  }

  &_Nav {
    text-align: center;
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    a {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;

        text-decoration: none;

        &:hover,
        &:focus {
          color: $dig-orange;
        }
      }
  }
}
}
</style>
