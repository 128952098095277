<template>
  <CookieNotice/>
  <Header :showLinks="true"/>
  <router-view/>
  <Footer :showLinks="true"/>
</template>

<script>
import Header from './components/HeaderNew.vue'
import Footer from './components/FooterNew.vue'
import CookieNotice from './components/CookieNotice.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    CookieNotice,
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

  /* a:focus, button:focus {
    outline: 2px solid orange blue;
  } */

  body {
    margin: 0;
    max-width: 100vw;
    height: 700px;
    overflow-x: hidden;
    font-family: 'Asap', sans-serif;
  }

  h2 {
    font-size: 5em;
    font-weight: 600;
    line-height: 0.9em;
  }
  h3 {
    font-size: 1.6em;
  }

  .a11y-hide {
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  /* @media screen and (max-width: 1000px) {
    #app {
      width: 100%;
    }
  } */

</style>
