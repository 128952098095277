<template>
  <div v-show="showNotice" class="Notice" :key="'cookie'+showNotice">
    <div class="Notice_Top">
      <img :class="['icon', 'after-icon']" src="@/assets/files/cookie.svg" alt=""/>
      <vue-markdown class="md" :source="cookieText" />
    </div>
    <div class="Notice_Bottom">
      <Button size="cookie" :type="'secondary'" @click="decline()">Decline</Button>
      <Button size="cookie" :type="'agree'" @click="accept()">Accept</Button>
    </div>
  </div>
</template>

<script>
import Button from "./UI/Button";

export default {
  name: "CookieNotice",
  components: {
    Button,
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_STRAPIURL,
      showNotice: true,
    };
  },
  setup() {
    const cookieText = "We use [third-party cookies](http://diginclusion.com/policy/PrivacyPolicy) to help us understand how people use our site.";

    return { cookieText };
  },
  mounted() {
    this.showNotice = this.hasInteracted();
    const hasAccepted = localStorage.getItem( 'cookie_notice_accepted' ) == 'true';
    if( hasAccepted ) this.injectTracking();
  },
  methods: {
    accept() {
      localStorage.setItem("cookie_notice_accepted", true);
      this.injectTracking();
      this.showNotice = false;
    },
    decline() {
      localStorage.setItem("cookie_notice_accepted", false);
      this.showNotice = false;
    },
    hasInteracted() {
      if (localStorage.getItem("cookie_notice_accepted") == null){
        return true;
      }
      return false;
    },
    injectTracking() {
      const head = document.getElementsByTagName('head')[0];

      const scriptA = document.createElement('script');
      scriptA.setAttribute( 'async', true );
      scriptA.setAttribute( 'src', 'https://www.googletagmanager.com/gtag/js?id=G-JPY4TR8C3K' );

      scriptA.onload = function() {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-JPY4TR8C3K');
        console.log( 'Google Analytics loaded' );
      }

      head.insertBefore(scriptA, head.children[1]);
    },
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables/_colors.scss";

.md {
  a {
      color: #ffffff;

      text-decoration: none;

      &:visited {
        color: white;
      }

      &:hover {
        color: $dig-orange;
      }

      &:focus {
        color: $dig-orange;
      }
    }
}

.Notice {
  z-index: 100;
  max-width: 381px;
  max-height: 140px;
  background-color: $dig-new-blue;

  position: fixed;
  bottom: 0;
  right: 0;

  color: white;
  font-size: 16px;
  line-height: 20px;

  padding: 16px;
  row-gap: 24px;

  &_Top {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    margin-bottom: 24px;
  }

  &_Bottom {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
}

@media screen and (max-width: 1050px) {
  .Notice {
  max-width: 100vw;
  max-height: 140px;
  left: 0;
  
  &_Top {
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 24px;
  }

  &_Bottom {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
}
}
</style>
