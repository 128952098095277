import { createApp, h, provide } from 'vue'
import { ApolloClients } from "@vue/apollo-composable";
import App from './App.vue'
import router from './router'
import {
  ApolloClient,
  // ApolloLink,
  InMemoryCache,
  // from,
  HttpLink,
} from "@apollo/client/core";

let httpEndpoint;

if(process.env.VUE_APP_STRAPIURL) {
  httpEndpoint = process.env.VUE_APP_STRAPIURL+'/graphql'
} else {
  httpEndpoint = 'https://digbackend.diginclusion.com/graphql'
  // httpEndpoint = 'http://localhost:1337/graphql'
  // httpEndpoint = 'http://192.168.20.20:1337/graphql'
}


const apolloClient = new ApolloClient({
  // uri: httpEndpoint,
  link: new HttpLink({ uri: httpEndpoint }),
  cache: new InMemoryCache()
})

// const apolloProvider = new VueApollo({
//   defaultClient: apolloClient,
// })

import VueMarkdown from 'vue3-markdown-it';


createApp({
    setup() {
      provide(ApolloClients, {
        default: apolloClient,
      });
    },
    render: () => h(App),
  })
  .component('vue-markdown', VueMarkdown)
  .use(router)
  .mount('#app')


// new Vue({
//   // apolloProvider: createProvider(),
//   router,
//   render: h => h(App)
// }).$mount('#app')