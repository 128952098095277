<template>
  <component :target="to.toLowerCase().startsWith('http') ?'_blank':''" :is="to.toLowerCase().startsWith('http') ? 'a' : 'router-link'" :class="[type, (direction==''?'direction-'+signal.direction:'direction-'+direction), (size==''?'size-'+signal.size:'size-'+size), (sub?'withsub':''), (short?'short':'')]" :to="to" :href="to" ref="button">
    <div :class="['flex-parent', (extraPad?'extraPad':'')]">
    <template v-if="icon.length&&(typeof icon[2]=='undefined'||icon[2]!='after')">
      <!-- <Icon class="icon" :type="icon[0]" :icon="icon[1]"/> -->
      &nbsp;
    </template>
    <span>
      <slot></slot>
    </span>
    <span class="sub" v-if="sub">
      {{sub}}
    </span>
    <template v-if="to.toLowerCase().startsWith('http') ? true : false">
      <img v-if="type=='secondary'" :class="['icon', 'after-icon']" src="@/assets/files/link-arrow-white.svg" alt=""/>
      <img v-else :class="['icon', 'after-icon']" src="@/assets/files/link-arrow.svg" alt=""/>
      <!-- <Icon class="icon after-icon" :type="icon[0]" :icon="icon[1]"/> -->
      <span class="screen-reader-only">(opens external site)</span>
    </template>
    <template v-if="icon.length&&(typeof icon[2]!='undefined')&&icon[2]=='after'">
      &nbsp;
      <!-- <Icon class="icon after-icon" :type="icon[0]" :icon="icon[1]"/> -->
    </template>
  </div>
  </component>
</template>

<script>
// import UIIcon from '@/components/UI/Icon';

export default {
  name: 'LinkButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: Array,
      default: () => {
        return []
      }
    },
    extraPad: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: ''
    },
    signal: {
      type: Object,
      default: () => {
        return {
          direction: 'horizontal',
          size: 'normal'
        }
      }
    },
    direction: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    sub: {
      type: String,
      default: ''
    },
    short: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    focus: function() {
      this.$refs.button.focus();
    },
  },
  components: {
    // Icon: UIIcon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colors.scss';

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

.flex-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extraPad {
  padding-left: 10px;
  padding-right: 10px;
}

button, a {
  
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border: 0;
  text-decoration: none;
  position: relative;
  text-wrap: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
  // padding-right: 8rem;
  text-align: left;
  display: inline-block;
  font-size: 18px;

  // &:before {
  //   position: absolute;
  //   content: "\203A";
  //   top: 50%;
  //   right: 2%;
  //   width: 20px;
  //   display: block;
  //   z-index: 1;
  //   font-size: 24px;
  //   margin-top: -14px;
  //   font-size: 1.4rem;
  //   margin-top: -.7rem;
  //   line-height: 1;
  // }
  .icon {
    margin-right: 5px;
    max-height: 24px;
    max-width: 20px;
  }

  .icon-secondary {
    fill: #FFFFFF;
    color:#FFFFFF;
  }
  .after-icon {
    margin-left: 15px;
  }

  &.short {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  &.withsub {
    .icon {
      margin-top: -6px;
      max-height: 24px;
    }
  }
  .sub {
    display: inline-block;
    font-size: 9pt;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.primary {
    color: $dig-text-dark;
    background-color: $dig-orange;
    &:focus, &:hover, &.focus {
      color: $dig-text-dark;
      background-color: #FFFFFF;
    }
  }

  &.secondary {
    color: #FFFFFF;
    background-color: $dig-new-blue;
    &:focus, &:hover, &.focus {
      color: white;
      background-color: $dig-purple;
    }
  }

  &.tertiary {
    color: #FFFFFF;
    background-color: $dig-purple;
    &:focus, &:hover, &.focus {
      color: $dig-dark;
      background-color: $dig-orange;
    }
  }

  // &.serious {
  //   color: $hugr-button-serious-text;
  //   background-color: $hugr-button-serious-bg;
  //   &:focus, &:hover, &.focus {
  //     color: $hugr-button-serious-text-focus;
  //     background-color: $hugr-button-serious-bg-focus;
  //   }
  // }

  // &.transparent {
  //   color: $hugr-button-transparent-text;
  //   background-color: $hugr-button-transparent-bg;
  //   border: 1px solid $hugr-button-transparent-border;
  //   &:focus, &:hover, &.focus {
  //     border: 1px solid $hugr-button-transparent-border-focus;
  //   }
  // }

  // &.border {
  //   color: $hugr-button-border-text;
  //   background-color: $hugr-button-border-bg;
  //   border: 1px solid $hugr-button-border-border;
  //   &:focus, &:hover, &.focus {
  //     color: $hugr-button-border-text-focus;
  //     background-color: $hugr-button-border-bg-focus;
  //     border: 1px solid $hugr-button-border-border-focus;
  //   }
  // }

  // &.icon {
  //   background: transparent;
  //   width: 26px;
  //   height: 26px;
  //   display: inline-block;
  //   padding: 0;
  //   span {
  //     color: transparent;
  //   }
  // }

  // &.switch {
  //   background: transparent;
  //   // width: 20px;
  //   // height: 20px;
  //   display: inline-block;
  //   padding: 0;
  //   color: #262e37;
  //   background-color: #ffb23e;
  //   &[aria-checked="true"] {
  //     color: #ffb23e;
  //     background-color: #262e37;
  //   }
  // }

  // &.edit {
  //   padding: 5px 5px 5px 24px;
  //   background: transparent;
  //   color: #5e646a;
  //   border: 1px solid transparent;

  //   &:hover, &:focus {
  //     color: #262e37;
  //     border: 1px solid #5e646a;
  //   }

  //   &:before {
  //     content: "\270E";
  //     left: 0;
  //     top: 55%;
  //   }
  // }

  //size and direction
  &.direction-vertical {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  &.size-small {
    padding: 10px 10px 10px 10px;
    font-size: 10pt;
  }
  &.size-micro {
    padding: 5px;
    font-size: 10pt;
    &:before { //this might not be needed
      content: '';
    }
  }
}
</style>
