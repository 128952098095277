import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     apiKey: 'home'
  //   }
  // },
  {
    path: '/uc',
    name: 'UnderConstruction',
    component: () => import('../views/UnderConstruction.vue'),
    meta: {
      apiKey: 'UnderConstruction'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      apiKey: 'home',
      title: 'Home'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
    meta: {
      apiKey: 'services',
      title: 'Services'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      apiKey: 'contact',
      title: 'Contact Us'
    }
  },
  {
    path: '/hugr',
    name: 'Hugr',
    component: () => import('../views/Hugr.vue'),
    meta: {
      apiKey: 'hugr',
      title: 'HUGR'
    }
  },
  {
    path: '/blog/:post+',
    name: 'BlogPost',
    component: () => import('../views/BlogPost.vue'),
  },
  {
    path: '/policy/:name',
    name: 'Policy',
    component: () => import('../views/Policy.vue'),
    meta: {
      title: 'Policies'
    }
  },
  {
    path: '/terms',
    redirect: '/policy/ServiceTerms',
    meta: {
      title: 'Terms of Service'
    }
  },
  {
    path: '/privacy-policy',
    redirect: '/policy/PrivacyPolicy',
    meta: {
      title: 'Privacy Policy'
    }
  },
  {
    path: '/policy',
    redirect: '/policy/Landing',
    meta: {
      title: 'Policies'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
    meta: {
      apiKey: 'blog',
      title: 'Blog'
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'About Us'
    }
  },
  {
    path: '/accreditation',
    name: 'Accreditation',
    component: () => import('../views/Accredited.vue'),
    meta: {
      title: 'Accreditation'
    }
  },
  {
    path: '/accredited/:id',
    name: 'AccreditedInstance',
    component: () => import('../views/AccreditedInstance.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'ModularPage',
    component: () => import('../views/ModularPage.vue'),
  },
/*   {
    path: '/meet-the-team',
    name: 'Team',
    component: () => import('../views/Team.vue')
  } */
  
  // {
  //   path: '/:page',
  //   name: 'StaticPage',
  //   component: () => import('../views/StaticPage.vue')
  // }
]

const router = new createRouter({
  routes,
  history: createWebHistory(),
  // scrollBehaviour () {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({ x: 0, y: 0 })
  //     }, 500)
  //   })
    // console.log('scrolling to top')
    // window.scrollTo(0, 0);
    // return { x: 0, y: 0 }
  // }
})

// const title = document.getElementsByTagName('head')[0].getElementsByTagName('title')[0];
// import gql from "graphql-tag"
// const { HttpLink } = require('apollo-link-http');
// const { ApolloClient } = require('apollo-client');
// const { InMemoryCache } = require('apollo-cache-inmemory');
// const httpLink = new HttpLink({
//     uri: process.env.VUE_APP_GRAPHQL_HTTP || 'localhost:1337',
// })

// const cache = new InMemoryCache()

// export const apolloClient = new ApolloClient({
//     link: httpLink,
//     cache
// })

router.beforeEach((to, _, next) => {
  if(to.name != 'BlogPost' && to.name != 'OurWorkClient' && to.name != 'ServicesDetails'){
    document.title = to.meta.title + ' | Dig Inclusion';
  }
  next();
});

// router.beforeEach((to, _, next) => {
//   let query;
//   if(to.name=='StaticPage') {
//     title.innerText = 'FishLocal | '+to.params.page.replace('/', '').replace(/-/g, ' ').split(' ').map(word => word[0].toUpperCase()+word.slice(1)).join(' ');
//     query = `
//       {
//         res: staticPageBySlug(slug: "${to.params.page}") {
//           pageTitle
//           meta {
//             type
//             nameOrProperty
//             content
//           }
//         }
//       }
//     `
//   } else {
//     title.innerText = 'Fishlocal | '+to.name;
//     query = `
//       {
//         res: ${to.meta.apiKey} {
//           pageTitle
//           meta {
//             type
//             nameOrProperty
//             content
//           }
//         }
//       }
//     `
//   }
//   clearMeta();
//   apolloClient.query({
//     query: gql`${query}`
//   }).then(res => {
//     if(res.data.res&&res.data.res.meta.length) {
//       for(let meta of res.data.res.meta) {
//         setMeta(meta);
//       }
//     }

//     if(res.data.res&&res.data.res.pageTitle) { //replace
//       title.innerText = 'Fishlocal | '+res.data.res.pageTitle;
//     }
//   }).catch(() => {

//   });
//   next();
// })

// function clearMeta() {
//   let oldMetas = document.querySelectorAll('meta[data-pagemeta]');
//   for(let meta of oldMetas) {
//     meta.parentElement.removeChild(meta);
//   }
// }

// function setMeta(meta) {
//   let metaEl = document.createElement('meta');
//   metaEl.setAttribute(meta.type, meta.nameOrProperty);
//   metaEl.setAttribute('content', meta.content);
//   metaEl.setAttribute('data-pagemeta', true);
//   document.getElementsByTagName('head')[0].appendChild(metaEl);
// }

export default router
